import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StorageHelperService } from './storage-helper.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private router: Router, private storageService: StorageHelperService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.getJWTToken()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  getJWTToken() {
    return this.storageService.getItemFromLocal('accessToken');
  }
}
