export const environment = {
    production: false,
    appInsightsInstrumentationKey: '',
    azureClientId: '',
    azureTenantId: '',
    secretId: '',
    apiVersion: '1',
    host: '',
    venueServiceHost: 'https://venue-management-service-fitzone-dev-app.azurewebsites.net/',
    userMgmntServiceHost: 'https://user-management-service-fitzone-dev-app.azurewebsites.net/',
    customerMgmtServiceHost: 'https://customer-management-service-fitzone-dev-app.azurewebsites.net/',
    fileMgmtServiceHost: 'https://file-management-service-fitzone-dev-app.azurewebsites.net/',
    subscriptionMgmtServiceHost: 'https://subscription-management-service-fitzone-dev-app.azurewebsites.net/',
    venueLeadServiceHost: 'https://venueleads-service-fitzone-dev-app.azurewebsites.net/',
    venueStaffManagementServiceHost: 'https://venue-staff-management-service-fitzone-dev-app.azurewebsites.net/',
    orderMgmtServiceHost: 'https://order-management-service-fitzone-dev-app.azurewebsites.net/',
    workoutMgmtServiceHost: 'https://workout-plan-management-service-fitzone-dev-app.azurewebsites.net/',
    gxPlannerManagementServiceHOst: 'https://gx-class-management-service-fitzone-dev-app.azurewebsites.net/',
    venueManagementServiceHOst: 'https://venue-management-service-fitzone-dev-app.azurewebsites.net/',
    subscriptionRecordServiceHost: 'https://subscription-management-service-fitzone-dev-app.azurewebsites.net/',
    attendanceServiceHost: 'https://attendance-management-service-fitzone-dev-app.azurewebsites.net/',
    helpDeskServiceHost: 'https://helpdesk-service-fitzone-dev-app.azurewebsites.net/',
    sessionServiceHost: 'https://personal-training-service-fitzone-dev-app.azurewebsites.net/',
    attendanceManagementServiceHOst: 'https://attendance-management-service-fitzone-dev-app.azurewebsites.net/',
    fitnessAssessmentServiceHost: 'https://fitness-assessement-service-fitzone-dev-app.azurewebsites.net/',
    reportManagementServiceHost:'https://report-management-service-fitzone-dev-app.azurewebsites.net/',
    rewardsManagementServiceHost:'https://reward-management-service-fitzone-dev-app.azurewebsites.net/',
    contentLibraryServiceHost:'https://file-management-service-fitzone-dev-app.azurewebsites.net/',
    targetManagementService:"https://target-management-service-fitzone-dev-app.azurewebsites.net/"
};