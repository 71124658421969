<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger transition-duration-300"
            (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
    </div>
    <!-- <div class="search-section" style="margin-left: -23%;">
        <li [class]="layoutService.isHorizontal() ? 'topbar-search hidden' : 'topbar-search hidden lg:block'">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText placeholder="Search" class="w-50rem lg:w-full"
                    style="width: 200% !important;" />
            </span>
        </li>
        <li [class]="layoutService.isHorizontal() ? 'block topbar-item' : 'block sm:hidden topbar-item'">
            <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden"
                leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
                <i class="pi pi-search"></i>
            </a>
            <ul [class]="'ng-hidden topbar-menu active-topbar-menu p-3 w-25rem  z-5'" style="bottom: -5.8rem">
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Search" class="w-full" />
                </span>
            </ul>
        </li>
    </div> -->
    <div class="layout-topbar-menu-section">
        <app-sidebar></app-sidebar>
    </div>

    <div class="topbar-end">
        <ul class="topbar-menu">

            <li class="topbar-item">
                <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden"
                    leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple class="cursor-pointer">
                    <img class="border-round-xl" src="assets/demo/images/avatar/square/avatar-m-1.jpg" alt="Profile" />
                </a>
                <ul [class]="'topbar-menu active-topbar-menu p-4 w-15rem   z-5 ng-hidden'">
                    <li role="menuitem" class="m-0 mb-3">
                        <a href="javascript:void(0);" (click)="redirectToProfile()"
                            class="flex align-items-center hover:text-primary-500 transition-duration-200"
                            pStyleClass="@grandparent" enterClass="ng-hidden" enterActiveClass="px-scalein"
                            leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-user mr-2"></i>
                            <span>Profile</span>
                        </a>
                    </li>

                    <li role="menuitem" class="m-0">
                        <a class="flex align-items-center hover:text-primary-500 transition-duration-200"
                            href="javascript:void(0)" (click)="logOut()" pStyleClass="@grandparent"
                            enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden"
                            leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-sign-out mr-2"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- <li class="">
                <button pButton type="button" icon="pi pi-cog" class="p-button-text p-button-secondary flex-shrink-0"
                    (click)="onConfigButtonClick()"></button>
            </li> -->
            <!-- <li class="">
                <button pButton type="button" icon="pi pi-arrow-left" class="p-button-text p-button-secondary flex-shrink-0" (click)="onSidebarButtonClick()"></button>
            </li> -->
        </ul>
    </div>
</div>