import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { TableComponent } from './components/table/table.component';
import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DateService } from './services/date.service';
import { TabMenuModule } from 'primeng/tabmenu';
import { AccordionModule } from 'primeng/accordion';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationDialogService } from './services/confirmationDialog.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { StyleClassModule } from 'primeng/styleclass';
import { EditorModule } from 'primeng/editor';
import { DialogService } from 'primeng/dynamicdialog';
import { ImageModule } from 'primeng/image';

@NgModule({
    declarations: [TableComponent, OnlyNumberDirective, SpinnerComponent],
    imports: [
        CommonModule,
        TableModule,
        FormsModule,
        ReactiveFormsModule,
        RatingModule,
        ButtonModule,
        SliderModule,
        InputTextModule,
        ToggleButtonModule,
        RippleModule,
        MultiSelectModule,
        DropdownModule,
        ImageModule,
        ProgressBarModule,
        ToastModule,
        NgxSpinnerModule,
        TabMenuModule,
        AccordionModule,
        InputTextareaModule,
        CascadeSelectModule,
        CalendarModule,
        CheckboxModule,
        FileUploadModule,
        GalleriaModule,
        DialogModule,
        MenuModule,
        BreadcrumbModule,
        InputMaskModule,
        SelectButtonModule,
        InputNumberModule,
        InputSwitchModule,
        TooltipModule,
        RadioButtonModule,
        BadgeModule,
        SidebarModule,
        EditorModule,
        StyleClassModule,
        ConfirmDialogModule
    ],
    providers: [
        DateService,
        DatePipe,
        CurrencyPipe,
        ConfirmationService,
        MessageService,
        ConfirmationDialogService,
        DialogService
    ],
    exports: [
        TableComponent,
        NgxSpinnerModule,
        OnlyNumberDirective,
        SpinnerComponent,
        ReactiveFormsModule,
        ToastModule,
        CommonModule,
        TableModule,
        FormsModule,
        RatingModule,
        ButtonModule,
        SliderModule,
        InputTextModule,
        ToggleButtonModule,
        RippleModule,
        MultiSelectModule,
        DropdownModule,
        ImageModule,
        ProgressBarModule,
        TabMenuModule,
        AccordionModule,
        InputTextareaModule,
        CascadeSelectModule,
        CalendarModule,
        CheckboxModule,
        FileUploadModule,
        GalleriaModule,
        DialogModule,
        MenuModule,
        BreadcrumbModule,
        InputMaskModule,
        SelectButtonModule,
        InputNumberModule,
        InputSwitchModule,
        TooltipModule,
        RadioButtonModule,
        BadgeModule,
        SidebarModule,
        EditorModule,
        StyleClassModule,
        ConfirmDialogModule
    ],
})
export class SharedModule { }
