import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { Router } from '@angular/router';
import { StorageHelperService } from '../modules/core/services/storage-helper.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent {
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    activeItem!: number;
    constructor(public layoutService: LayoutService,
        public el: ElementRef,
        private router: Router,
        private storageHelperService: StorageHelperService) { }

    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    redirectToProfile() {
        this.router.navigate(['/home/profile']);
    }

    //logout event
    logOut() {
        if (this.storageHelperService.getItemFromLocal("isLoggedIn")) {
            this.storageHelperService.clearStorage();
            this.router.navigateByUrl('/login');
        }
    }
}
