<div class="surface-0">
    <div
        class="flex align-items-center justify-content-between flex-column h-screen"
    >
        <div
            class="flex flex-column align-items-center justify-content-center w-full md:w-4 h-full text-center py-6 px-4"
        >
            <a [routerLink]="['/']" class="mb-6">
                <img
                    src="./../../assets/Fitzone_Logo.svg"
                    alt="Fit.Zone Logo"
                    style="width: 100%"
                />
            </a>
            <div class="flex flex-column">
                <!-- email -->
                <ng-container *ngIf="!isOtpSent" [formGroup]="emailForm">
                    <span class="p-input-icon-left w-full mb-4">
                        <i class="pi pi-envelope"></i>
                        <input
                            id="email"
                            type="email"
                            pInputText
                            formControlName="email"
                            class="w-full md:w-25rem text-color-secondary surface-50 border-200"
                            placeholder="Email"
                        />
                        <div *ngIf="error" class="error">{{error}}</div>
                        <div
                    class="error"
                    *ngIf="emailForm.controls['email'].value && emailForm.controls['email'].errors?.['notValidEmail']"
                  >
                  Please enter a valid email.
                  </div>
                    </span>
                    <button
                        pButton
                        pRipple
                        [disabled]="emailForm.invalid"
                        label="Send OTP"
                        class="w-full mb-4"
                        (click)="sendOtp()"
                    ></button>
                </ng-container>

                <!-- OTP -->
                <ng-container *ngIf="isOtpSent">
                    <h3>Enter Verification Code</h3>
                    <p>
                        We have sent you a 4 digit OTP to
                        <span style="font-weight: bold; overflow-wrap: anywhere;"
                            >{{ emailForm.value.email }}&nbsp;&nbsp;</span
                        >
                        <a href="" (click)="isOtpSent = false">Edit</a>
                    </p>
                    <span class="container inputfield">
                        <input
                            id="otp1"
                            type="text"
                            pInputText
                            [formControl]="otp1"
                            class="otp-input"
                            maxlength="1"
                            min="0"
                            max="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            onkeydown="
    if (/^[eE.\+\-]$/.test(event.key))
      event.preventDefault();" 
      onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
                            (keyup)="keyUp1($event)"
                        />
                        <input
                            id="otp2"
                            type="text"
                            pInputText
                            [formControl]="otp2"
                            class="otp-input"
                            maxlength="1"
                            min="0"
                            max="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            onkeydown="
                            if (/^[eE.\+\-]$/.test(event.key))
                              event.preventDefault();" 
                              onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
                            (keyup)="keyUp2($event)"
                        />
                        <input
                            id="otp3"
                            type="text"
                            pInputText
                            [formControl]="otp3"
                            class="otp-input"
                            maxlength="1"
                            min="0"
                            max="9"
                            step="1"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            onkeydown="
                            if (/^[eE.\+\-]$/.test(event.key))
                              event.preventDefault();" 
                              onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
                            (keyup)="keyUp3($event)"
                        />
                        <input
                            id="otp4"
                            type="text"
                            pInputText
                            [formControl]="otp4"
                            class="otp-input"
                            maxlength="1"
                            min="0"
                            max="9"
                            oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                            onkeydown="
                            if (/^[eE.\+\-]$/.test(event.key))
                              event.preventDefault();" 
                              onPaste="  if (!/^\d+$/.test(event.clipboardData.getData('text/plain')))event.preventDefault();"
                            
                            (keyup)="keyUp4($event)"
                        />
                    </span>
                    <div class="error" *ngIf="wrongOtpCount > 0 && wrongOtpCount < 2">
                        Please enter correct OTP
                    </div>
                    
                    <countdown
                        *ngIf="!isResentOTP && resendOtpCount < 2 && wrongOtpCount < 2"
                        #cd
                        [config]="{ leftTime: 30, format: 'm:s' }"
                        (event)="handleTimerEvent($event)"
                    ></countdown>
                    <div class="otp-resend">
                        <a
                            class="resend-otp"
                            *ngIf="isResentOTP && wrongOtpCount < 2"
                            (click)="otpCount(); sendOtp()"
                            >Resend OTP</a
                        >
                    </div>
                    <br />
                    <button
                        pButton
                        pRipple
                        label="Login"
                        class="w-full mb-4"
                        [disabled]="
                            otp1.invalid ||
                            otp2.invalid ||
                            otp3.invalid ||
                            otp4.invalid
                        "
                        (click)="submitOtp()"
                    ></button>
                </ng-container>
            </div>
        </div>
        <div class="flex flex-wrap align-items-center pb-8 px-4">
            <h6 class="m-0 font-medium text-300" style="line-height: 17px">
                Copyright Ⓒ Fitzone Technologies Private Limited
            </h6>
        </div>
    </div>
</div>

<app-config [minimal]="true"></app-config>
