import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
    model: any[] = [];
    permissionDashboard = false;
    permissionGetAllVenueLead = false;
    permissionGetAllExistingCustomers = false;
    permissionGetRecentlyAssignedCustomers = false;
    permissionGetTrialCustomers = false;
    permissionGetAllStaff = false;
    permissionPTPackageDefinition = false;
    permissionGetStaffAttendanceByDateRange = false;
    permissionGetAllShift = false;
    permissionGetAllRoster = false;
    permissionGetAllGxSchedule = false;
    Venue_GetVenueBaseProfiles = false;
    permissionAllCustomer = false;
    permissionSubscription = false;
    permissionPackage = false;
    permissionOffer = false;
    permissionAttendance = false;
    permissionOrder = false;
    permissionGx = false;
    userPermission = false;
    supportPermission = false;
    workoutPermission = false;
    workoutPlannerPermission = false;
    workoutLibraryPermission = false;
    ngOnInit() {
        const allAccess = JSON.parse(localStorage.getItem('Access') as string);
        console.log('!!!!!!!!!', allAccess);
        this.permissionDashboard = allAccess.includes('Dashboard')
            ? true
            : false;
        this.permissionGetAllVenueLead = allAccess.includes(
            'VenueLead_SearchLeadsWithPagination'
        )
            ? true
            : false;
        this.permissionGetAllExistingCustomers = allAccess.includes(
            'GetAllExistingCustomers'
        )
            ? true
            : false;
        this.permissionGetRecentlyAssignedCustomers = allAccess.includes(
            'GetRecentlyAssignedCustomers'
        )
            ? true
            : false;
        this.permissionGetTrialCustomers = allAccess.includes(
            'GetTrialCustomers'
        )
            ? true
            : false;
        this.permissionGetAllStaff = allAccess.includes(
            'VenueStaff_SearchVenueStaffWithPagination'
        )
            ? true
            : false;
        this.permissionPTPackageDefinition = allAccess.includes(
            'GetAllPTPackageDefinition'
        )
            ? true
            : false;
        this.permissionGetStaffAttendanceByDateRange = allAccess.includes(
            'GetStaffAttendanceByDateRange'
        )
            ? true
            : false;
        this.permissionGetAllShift = allAccess.includes(
            'VenueStaff_SearchVenueStaffWithPagination'
        )
            ? true
            : false;
        this.permissionGetAllRoster = allAccess.includes('GetAllRoster')
            ? true
            : false;
        this.permissionGetAllGxSchedule = allAccess.includes('GetAllGxSchedule')
            ? true
            : false;

        this.Venue_GetVenueBaseProfiles = allAccess.includes(
            'Venue_SearchVenuePagination'
        )
            ? true
            : false;
        this.permissionAllCustomer = allAccess.includes(
            'CustomerProfile_SearchCustomerWithPagination'
        )
            ? true
            : false;
        this.permissionSubscription = allAccess.includes(
            'Susbcription_SearchSubscriptionsWithPagination'
        )
            ? true
            : false;
        this.permissionPackage = allAccess.includes(
            'Susbcription_SearchSubscriptionsWithPagination'
        )
            ? true
            : false;
        this.permissionOffer = allAccess.includes(
            'GenericOffer_SearchGenericOffersWithPagination'
        )
            ? true
            : false;
        this.permissionAttendance = allAccess.includes(
            'Attendance_SearchAttendanceWithPagination'
        )
            ? true
            : false;
        this.permissionOrder = allAccess.includes(
            'Order_SearchOrdersWithPagination'
        )
            ? true
            : false;
        this.permissionGx = allAccess.includes(
            'GxSessions_SearchGXclasswithPagination'
        )
            ? true
            : false;
        this.userPermission = allAccess.includes('User_SearchUsersWithPaging')
            ? true
            : false;
        this.supportPermission = allAccess.includes('Support') ? true : false;
        this.workoutPermission = allAccess.includes(
            'WorkOutHistory_SearchWorkOutHistoryWithPagination'
        )
            ? true
            : false;
        this.workoutPlannerPermission = allAccess.includes(
            'WorkOutPlan_GetWorkOutLevels'
        )
            ? true
            : false;
        this.workoutLibraryPermission = allAccess.includes(
            'WorkOutPlan_GetWorkOutLevels'
        )
            ? true
            : false;
        this.model = [
            {
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'My Dashboard',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/home/dashboard'],
                        show: true,
                    },
                    {
                        label: 'Venues',
                        icon: 'pi pi-fw pi-map',
                        routerLink: ['/home/venue-management/venues'],
                        show: this.Venue_GetVenueBaseProfiles,
                    },
                    {
                        label: 'Team Management',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/home/user-management/users'],
                        show: this.userPermission,
                    },
                    {
                        label: 'Customer Management',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/home/customer-management/customers'],
                        show: this.permissionAllCustomer,
                    },
                    {
                        label: 'Venue Leads',
                        icon: 'pi pi-fw pi-image',
                        routerLink: ['/home/venue-leads'],
                        show: this.permissionGetAllVenueLead,
                    },
                    {
                        label: 'Subscription',
                        icon: 'pi pi-fw pi-map',
                        items: [
                            {
                                label: 'Package',
                                icon: 'pi pi-fw pi-map',
                                routerLink: [
                                    '/home/subscription-management/package-list',
                                ],
                                show: this.permissionSubscription,
                            },
                            {
                                label: 'Generic Offers',
                                icon: 'pi pi-fw pi-map',
                                routerLink: [
                                    '/home/subscription-management/generic-offer-list',
                                ],
                                show: this.permissionOffer,
                            },
                        ],

                        show: this.permissionSubscription,
                    },
                    {
                        label: 'Partner Management',
                        icon: 'pi pi-fw pi-user-edit',
                        items: [
                            {
                                label: 'GX Planner',
                                icon: 'pi pi-fw pi-calendar',
                                routerLink: ['/home/gx-planner'],
                                show: this.permissionGx,
                            },
                            {
                                label: 'Partner Staff',
                                icon: 'pi pi-fw pi-users',
                                routerLink: [
                                    '/home/partner-portal-management/staff-management',
                                ],
                                show: this.permissionGetAllStaff,
                            },
                            {
                                label: 'Staff Attendance',
                                icon: 'pi pi-fw pi-check',
                                routerLink: ['/home/staff-attendance'],
                                show: this.permissionAttendance,
                            },
                        ],
                        show: this.permissionGetAllStaff,
                    },
                    {
                        label: 'Rewards',
                        icon: 'pi pi-fw pi-gift',
                        items: [
                            {
                                label: 'Rewards Config',
                                icon: 'pi pi pi-fw pi-gift',
                                routerLink: [
                                    '/home/rewards-management/rewards',
                                ],
                                show: this.permissionGx,
                            },
                        ],
                        show: this.permissionGetAllStaff,
                    },
                    {
                        label: 'Order and Payment',
                        icon: 'pi pi-fw pi-wallet',
                        routerLink: ['/home/order-and-payment-management'],
                        show: this.permissionOrder,
                    },
                    {
                        label: 'Content Library',
                        icon: 'pi pi-fw pi-wallet',
                        routerLink: ['/home/content-library/libraries-list'],
                        show: this.permissionOrder,
                    },

                    {
                        icon: 'pi pi-inbox',
                        label: 'Workout',
                        show: this.workoutPermission,
                        items: [
                            {
                                label: 'Workout Planner',
                                icon: 'pi pi-fw pi-sitemap',
                                routerLink: [
                                    '/home/workout-management/workout-planner',
                                ],
                                show: this.workoutPlannerPermission,
                            },
                            {
                                label: 'Exercise Library',
                                icon: 'pi pi-fw pi-book',
                                routerLink: [
                                    '/home/workout-management/excercise-library',
                                ],
                                show: this.workoutLibraryPermission,
                            },
                        ],
                    },
                    {
                        label: 'Target Management',
                        icon: 'pi pi-arrow-circle-up',
                        routerLink: ['/home/target-management'],
                        show: this.permissionOrder,
                    },
                    {
                        label: 'Admin-Rights',
                        icon: 'pi pi-arrow-circle-up',
                        routerLink: ['/home/admin-rights'],
                        show: this.permissionOrder,
                    },
                    {
                        label: 'Support',
                        icon: 'pi pi-fw pi-comments',
                        routerLink: ['/home/support'],
                        show: this.supportPermission,
                    },
                ],
            },
        ];
    }
}
