import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class StorageHelperService {
  constructor() { }

  public getItemFromLocal(key: string) {
    if (localStorage.getItem(key)) {
      return this.base64ToJson(localStorage.getItem(key)!);
    }
  }

  public setItemToLocal(key: string, value: any) {
    return localStorage.setItem(key, this.jsonToBase64(value));
  }

  public removeItemFromLocal(key: string) {
    return localStorage.removeItem(key);
  }

  public getItemFromSession(key: string) {
    if (sessionStorage.getItem(key)) {
      return this.base64ToJson(sessionStorage.getItem(key)!);
    }
  }

  public setItemToSession(key: string, value: any) {
    return sessionStorage.setItem(key, this.jsonToBase64(value));
  }

  public removeItemFromSession(key: string) {
    return sessionStorage.removeItem(key);
  }

  public clearSessionStorage() {
    sessionStorage.clear();
  }

  public clearLocalStorage() {
    localStorage.clear();
  }

  public clearStorage() {
    this.clearLocalStorage();
    this.clearSessionStorage();
  }

  public jsonToBase64(object: any) {
    const json = JSON.stringify(object);
    //return Buffer.from(json).toString("base64");
    return window.btoa(json);
  }

  public base64ToJson(base64String: string) {
    //const json = Buffer.from(base64String, "base64").toString();
    const json = window.atob(base64String);
    return JSON.parse(json);
  }
}
