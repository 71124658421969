import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Table } from 'primeng/table';
import { Customer, Representative } from 'src/app/demo/api/customer';
import { TableConfig } from '../../models/TableConfigModel';
import * as Constants from '../../../core/constants/app.consts';
interface expandedRows {
    [key: string]: boolean;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent {
    customers1: Customer[] = [];
    representatives: Representative[] = [];
    statuses: any[] = [];
    zones$: any = Constants.zones;
    activityValues: number[] = [0, 100];
    @Input() tableConfig!: TableConfig;
    @Input() buttonText!: string;
    @Input() showButton: boolean = false;
    @ViewChild('filter') filter!: ElementRef;
    @Output() emitAdd = new EventEmitter();
    @Output() emitDelete = new EventEmitter();
    @Output() emitEdit = new EventEmitter();
    @Output() emitView = new EventEmitter();
    @Output() emitButtonEvent = new EventEmitter();
    @Output() emitPagination = new EventEmitter();
    @Output() emitStateFilter = new EventEmitter<string>();
    @Output() emitZoneFilter = new EventEmitter<string>();
    @Output() emitVenueTypeFilter = new EventEmitter<string>();
    stateOptions: any[] = [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
        { label: 'De-registered', value: 'De-registered' },
    ];
    venueTypes: any[] = [
        { label: 'Champion', value: 'champion' },
        { label: 'Premium', value: 'premium' },
    ];
    selectedState: string | undefined = '';
    selectedZone: string | undefined = '';
    selectedVenueType: string | undefined = '';
    originalTableData: any[] = [];
    constructor() {}

    ngOnInit() {
        this.originalTableData = [...this.tableConfig.tableData];
    }

    formatCurrency(value: number) {
        return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    onGlobalFilter(table: Table, event: Event) {
        table.filterGlobal(
            (event.target as HTMLInputElement).value,
            'contains'
        );
    }

    clear(table: Table) {
        table.clear();
        this.filter.nativeElement.value = '';
        this.selectedState = undefined;
        this.selectedZone = undefined;
        this.selectedVenueType = undefined;
        this.onStateFilterChange(null);
        this.onZoneFilterChange(null);
        this.onVenueTypeFilterChange(null);
    }

    onAdd(event: any) {
        this.emitAdd.emit(event);
    }

    onStateFilterChange(event: any) {
        this.emitStateFilter.emit(this.selectedState);
    }

    onZoneFilterChange(event: any) {
        this.emitZoneFilter.emit(this.selectedZone);
    }

    onVenueTypeFilterChange(event: any) {
        this.emitVenueTypeFilter.emit(this.selectedVenueType);
    }

    //action event of table
    actionEvent(event: any, item: any) {
        if (event.eventType === 'EDIT') {
            this.emitEdit.emit(item);
        } else if (event.eventType === 'VIEW') {
            this.emitView.emit(item);
        } else if (event.eventType === 'DELETE') {
            this.emitDelete.emit(item);
        }
    }

    //button or icon event in table
    onButtonEvent(event: any, rowData: any, columnData: any) {
        let eventItem = { event, rowData, columnData };
        this.emitButtonEvent.emit(eventItem);
    }

    //pagination event
    pagination(event: any) {
        let globalFilterObj: Record<string, any> = {};
        const page = Math.floor(event.first / event.rows) + 1;
        const rows = event.rows;
        globalFilterObj = {
            searchText: event.globalFilter,
            searchKey: null,
            searchValue: null,
            page: page,
            rows: rows,
        };
        this.emitPagination.emit(globalFilterObj);
    }
}
