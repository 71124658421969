<div class="card border-round-2xl p-0">
    <div class="col-12">
        <div class="w-12 pb-3 flex align-items-center justify-content-between">
            <h5 class="mb-0">{{ tableConfig.title }}</h5>
            <button
                pButton
                [label]="buttonText"
                class="p-button-outlined mb-2"
                icon="pi pi-plus-circle"
                (click)="onAdd($event)"
                *ngIf="showButton"
            ></button>
        </div>
        <p-table
            #dt1
            [value]="tableConfig.tableData"
            dataKey="id"
            [rows]="tableConfig.pagesize"
            [totalRecords]="tableConfig.totalRecords"
            [loading]="tableConfig.loading"
            [rowHover]="true"
            [rowsPerPageOptions]="[10, 15, 25]"
            styleClass="p-datatable-gridlines"
            [paginator]="true"
            [lazy]="true"
            (onLazyLoad)="pagination($event)"
            [globalFilterFields]="tableConfig.globalFilterFields"
            responsiveLayout="scroll"
        >
            <ng-template pTemplate="caption">
                <div class="flex gap-4 flex-column sm:flex-row">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            #filter
                            (input)="onGlobalFilter(dt1, $event)"
                            placeholder="Search Keyword"
                            class="w-full"
                        />
                    </span>
                    <ng-container *ngIf="tableConfig.showStateFilter">
                        <p-dropdown
                            [options]="stateOptions"
                            [(ngModel)]="selectedState"
                            placeholder="Select State"
                            (onChange)="onStateFilterChange($event)"
                        ></p-dropdown>
                    </ng-container>
                    <ng-container *ngIf="tableConfig.showZoneFilter">
                        <p-dropdown
                            [options]="zones$"
                            [(ngModel)]="selectedZone"
                            placeholder="Select Zone"
                            (onChange)="onZoneFilterChange($event)"
                            optionLabel="name"
                            optionValue="code"
                        ></p-dropdown>
                    </ng-container>
                    <ng-container *ngIf="tableConfig.showVenueTypeFilter">
                        <p-dropdown
                            [options]="venueTypes"
                            [(ngModel)]="selectedVenueType"
                            placeholder="Select Venue Type"
                            (onChange)="onVenueTypeFilterChange($event)"
                            optionLabel="label"
                            optionValue="value"
                        ></p-dropdown>
                    </ng-container>
                    <button
                        pButton
                        label="Clear"
                        class="p-button-outlined"
                        icon="pi pi-filter-slash"
                        (click)="clear(dt1)"
                    ></button>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <ng-container *ngFor="let item of tableConfig.tableColumns">
                        <th style="width: 10rem">
                            <div
                                class="flex justify-content-between align-items-center"
                            >
                                {{ item.textName }}
                            </div>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                <tr>
                    <ng-container *ngFor="let item of tableConfig.tableColumns">
                        <td>
                            <ng-container
                                *ngIf="
                                    item.eventName !== 'button' &&
                                        item.eventName !== 'action' &&
                                        item.eventName !== 'image';
                                    else buttonIconComponent
                                "
                            >
                                {{ customer[item.fieldName] }}
                            </ng-container>
                            <ng-template #buttonIconComponent>
                                <ng-container
                                    *ngIf="item.eventName === 'button'"
                                >
                                    <ng-container
                                        *ngFor="
                                            let btnItem of item.buttonOption
                                        "
                                    >
                                        <ng-container
                                            *ngIf="
                                                btnItem.eventName === 'ICON';
                                                else buttonComponent
                                            "
                                        >
                                            <span
                                                class="mr-3"
                                                [pTooltip]="btnItem.toolTip"
                                                *ngIf="
                                                    (customer[item.fieldName] &&
                                                        customer[item.fieldName]
                                                            .length) ||
                                                    btnItem.visible
                                                "
                                            >
                                                <i
                                                    [class]="btnItem.icon"
                                                    (click)="
                                                        onButtonEvent(
                                                            btnItem,
                                                            customer,
                                                            customer[
                                                                item.fieldName
                                                            ]
                                                        )
                                                    "
                                                ></i>
                                                <ng-container
                                                    *ngIf="
                                                        btnItem.showText &&
                                                        customer[
                                                            item.fieldName
                                                        ] &&
                                                        customer[item.fieldName]
                                                            .length
                                                    "
                                                >
                                                    ({{
                                                        customer[item.fieldName]
                                                            .length
                                                    }})
                                                </ng-container>
                                            </span>
                                        </ng-container>
                                        <ng-template #buttonComponent>
                                            <p-button
                                                class="p-button-outlined mr-2"
                                                [label]="btnItem.textName"
                                                (click)="
                                                    onButtonEvent(
                                                        btnItem,
                                                        customer,
                                                        customer[item.fieldName]
                                                    )
                                                "
                                            ></p-button>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                                <ng-container
                                    *ngIf="item.eventName === 'action'"
                                >
                                    <ng-container
                                        *ngFor="
                                            let btnItem of item.actionOption
                                        "
                                    >
                                        <ng-container *ngIf="btnItem.visible">
                                            <i
                                                [class]="btnItem.icon"
                                                style="margin-right: 1rem"
                                                (click)="
                                                    actionEvent(
                                                        btnItem,
                                                        customer
                                                    )
                                                "
                                            ></i>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container
                                    *ngIf="item.eventName === 'image'"
                                >
                                    <div class="w-fit m-auto">
                                        <p-image
                                            class="block"
                                            [src]="customer[item.fieldName]"
                                            [alt]="customer.name"
                                            width="100px"
                                            height="50px"
                                            [title]="customer.description"
                                            [preview]="true"
                                        ></p-image>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No Content found.</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="loadingbody">
                <tr>
                    <td colspan="8">Loading Content. Please wait.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
