import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { LoginService } from './login.service';
import { StorageHelperService } from '../core/services/storage-helper.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CountdownComponent, CountdownConfig } from "ngx-countdown";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ValidatorService } from '../shared/services/validator.service';
import { AppToastService } from '../core/services/app-toast.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @ViewChild('cd', { static: false }) public countdown!: CountdownComponent;
    countdownConfig: CountdownConfig = {
        leftTime: 30, // 24 hours in milliseconds
        demand: true,
        notify: 0,
    };

    rememberMe: boolean = false;
    isOtpSent: boolean = false;
    isResentOTP = false;
resendOtpCount: number =  0;
wrongOtpCount: number = 0;
    email: FormControl = new FormControl();
    // otp: FormControl = new FormControl();
    otp1: FormControl = new FormControl();
    otp2: FormControl = new FormControl();
    otp3: FormControl = new FormControl();
    otp4: FormControl = new FormControl();
    error: any;
    emailForm: any;

    constructor(private layoutService: LayoutService,
        private toasterService: AppToastService,
        private loginService: LoginService,private fb: FormBuilder,
        private storageService: StorageHelperService,private validatorService: ValidatorService,
        private router: Router) {
        
        this.otp1.setValidators([Validators.required]);
        this.otp2.setValidators([Validators.required]);
        this.otp3.setValidators([Validators.required]);
        this.otp4.setValidators([Validators.required]);
        if (this.storageService.getItemFromLocal("isLoggedIn")) {
            this.router.navigate(['/home']);
        }
    }

    ngOnInit(): void {
        this.emailForm = this.fb.group({
            email: [
                '',
                [
                    this.validatorService.noWhitespaceValidator,
                    this.validatorService.emailValidator,
                ],
            ],
        });
     }

    get dark(): boolean {
        return this.layoutService.config.colorScheme !== 'light';
    }
    
    /**
     * @description submit the email to generate the OTP
     */
    sendOtp() {
        this.otp1.reset();
        this.otp2.reset();
        this.otp4.reset();
        this.otp3.reset();
        if (this.emailForm.valid) {
            this.error = '';
            this.loginService.generateToken(this.emailForm.value.email.trim()).subscribe({
                next: (otpDetails: any) => {
                    this.isOtpSent = true;
                    if (this.isOtpSent) {
                        this.toasterService.showSuccess('OTP sent successfully');
                        this.isResentOTP = false;
                    }
                    this.storageService.setItemToLocal('userID', otpDetails.userID);
                    document.getElementById('otp1')?.focus();
                },
                error: (err) => {
                    this.error = err.error.errors.Email[0];
                },
            });
        }
    }

    handleTimerEvent(event: any) {
        if (event.left === 0 && this.resendOtpCount < 2) {
            this.isResentOTP = true;
        }
    }

    keyUp1(e: any) {
        if (e.target.value.length == 1 && e.key !== 'Backspace') {
            document.getElementById('otp2')?.focus();
        } else if (e.key === 'Backspace') {
            document.getElementById('otp1')?.focus();
        }
    }

    keyUp2(e: any) {
        if (e.target.value.length == 1 && e.key !== 'Backspace') {
            document.getElementById('otp3')?.focus();
        } else if (e.key === 'Backspace') {
            document.getElementById('otp1')?.focus();
        }
    }

    keyUp3(e: any) {
        if (e.target.value.length == 1 && e.key !== 'Backspace') {
            document.getElementById('otp4')?.focus();
        } else if (e.key === 'Backspace') {
            document.getElementById('otp2')?.focus();
        }
    }

    keyUp4(e: any) {
        if (e.key === 'Backspace') {
            document.getElementById('otp3')?.focus();
        }
    }

    /**
     * @description submit OTP to validate and login
     */
    submitOtp() {
        if (this.otp1.valid && this.otp2.valid && this.otp3.valid && this.otp4.valid) {
            const params = {
                userid: this.storageService.getItemFromLocal('userID'),
                otp: this.otp1.value.toString() + this.otp2.value.toString() + this.otp3.value.toString() + this.otp4.value.toString(),
            };
            this.loginService.validateOtp(params).subscribe({
                next: (loginDetails: any) => {
                    if (loginDetails.isSuccess) {
                        this.storageService.setItemToLocal('accessToken', loginDetails.token.accessToken);
                        this.storageService.setItemToLocal('refreshToken', loginDetails.token.refreshToken);
                        this.storageService.setItemToLocal('refreshTokenExpiry', loginDetails.token.refreshTokenExpiry);
                        this.storageService.setItemToLocal("isLoggedIn", loginDetails.isSuccess);
                        const helper = new JwtHelperService();

                        const decodedToken = helper.decodeToken(
                            loginDetails.token.accessToken
                        );

                        // Other functions
                        const expirationDate = helper.getTokenExpirationDate(
                            loginDetails.token.accessToken
                        );
                        const isExpired = helper.isTokenExpired(
                            loginDetails.token.accessToken
                        );
                        console.log(decodedToken, expirationDate, isExpired);
                        localStorage.setItem('Access', JSON.stringify(decodedToken.applicationclaims));
                        this.callApis();
                        this.router.navigate(['/home']);
                    }
                },
                error: (err) => {
                    if(!err.error.issuccess && err.error.statusmessage == 'Wrong OTP has been entered!'){
                        this.wrongOtpCount++;
                        if(this.wrongOtpCount == 2){
                            this.isOtpSent = false;
                            this.toasterService.showError('Please login again.');
                            this.wrongOtpCount = 0;
                            
                        }
                    }
                    this.otp1.reset();
                    this.otp2.reset();
                    this.otp4.reset();
                    this.otp3.reset();
                },
            });
        }
    }

    callApis() {
        const getAllRoles$ = this.loginService.getAllRoles();
        const getAllVenues$ = this.loginService.fetchAllVenuesByUser(
            this.storageService.getItemFromLocal("userID")
        );
        forkJoin([getAllRoles$]).subscribe({
            next: (res: any) => {
                this.storageService.setItemToLocal('allRoles', res[0]);
            },
        });
    }
    otpCount(){
        if(this.resendOtpCount < 2){
            this.resendOtpCount++;
        }
    }
}
