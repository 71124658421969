import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { LoginModule } from './modules/login/login.module';
import { AuthGuard } from './modules/core/services/auth.guard';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled',
};

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: () =>
            import('./modules/login/login.module').then((m) => LoginModule),
    },
    {
        path: 'home',
        component: AppLayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('./modules/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
            },
            {
                path: 'admin-rights',
                loadChildren: () =>
                    import('./modules/rbac-ui/rbac-ui.module').then(
                        (m) => m.RbacUiModule
                    ),
            },
            {
                path: 'venue-management',
                loadChildren: () =>
                    import(
                        './modules/venue-management/venue-management.module'
                    ).then((m) => m.VenueManagementModule),
            },
            {
                path: 'user-management',
                loadChildren: () =>
                    import(
                        './modules/user-management/user-management.module'
                    ).then((m) => m.UserManagementModule),
            },
            {
                path: 'customer-management',
                loadChildren: () =>
                    import(
                        './modules/customer-management/customer-management.module'
                    ).then((m) => m.CustomerManagementModule),
            },
            {
                path: 'subscription-management',
                loadChildren: () =>
                    import(
                        './modules/subscription-management/subscription-management.module'
                    ).then((m) => m.SubscriptionManagementModule),
            },
            {
                path: 'target-management',
                data: { breadcrumb: 'Target-Management' },
                loadChildren: () =>
                    import('./modules/target/target.module').then(
                        (m) => m.TargetModule
                    ),
            },
            {
                path: 'venue-leads',
                data: { breadcrumb: 'Venue Leads' },
                loadChildren: () =>
                    import('./modules/venue-leads/venue-leads.module').then(
                        (m) => m.VenueLeadsModule
                    ),
            },
            {
                path: 'partner-portal-management/staff-management',
                data: { breadcrumb: 'Staff Management' },
                loadChildren: () =>
                    import(
                        './modules/staff-management/staff-management.module'
                    ).then((m) => m.StaffManagementModule),
            },
            {
                path: 'order-and-payment-management',
                loadChildren: () =>
                    import(
                        './modules/orders-and-payment-management/orders-and-payment-management.module'
                    ).then((m) => m.OrdersAndPaymentManagementModule),
            },
            {
                path: 'content-library',
                loadChildren: () =>
                    import(
                        './modules/content-librabry/content-librabry.module'
                    ).then((m) => m.ContentLibrabryModule),
            },
            {
                path: 'workout-management',
                loadChildren: () =>
                    import(
                        './modules/workout-management/workout-management.module'
                    ).then((m) => m.WorkoutManagementModule),
            },
            {
                path: 'gx-planner',
                data: { breadcrumb: 'GX Planner' },
                loadChildren: () =>
                    import('./modules/gx-planner/gx-planner.module').then(
                        (m) => m.GxPlannerModule
                    ),
            },
            {
                path: 'gx-schedule',
                data: { breadcrumb: 'GX Schedule' },
                loadChildren: () =>
                    import('./modules/gx-schedule/gx-schedule.module').then(
                        (m) => m.GxScheduleModule
                    ),
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('./modules/profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
            },
            {
                path: 'rewards-management',
                loadChildren: () =>
                    import(
                        './modules/rewards-management/rewards-management.module'
                    ).then((m) => m.RewardsManagementModule),
            },
            {
                path: 'staff-attendance',
                data: { breadcrumb: 'Staff Attendance' },
                loadChildren: () =>
                    import(
                        './modules/staff-attendance/staff-attendance.module'
                    ).then((m) => m.StaffAttendanceModule),
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'auth',
        data: { breadcrumb: 'Auth' },
        loadChildren: () =>
            import('./demo/components/auth/auth.module').then(
                (m) => m.AuthModule
            ),
    },
    {
        path: 'landing',
        loadChildren: () =>
            import('./demo/components/landing/landing.module').then(
                (m) => m.LandingModule
            ),
    },
    {
        path: 'notfound',
        loadChildren: () =>
            import('./demo/components/notfound/notfound.module').then(
                (m) => m.NotfoundModule
            ),
    },
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
    declarations: [],
})
export class AppRoutingModule {}
